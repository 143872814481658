.modiadLogo {
  user-select: none;
  position: absolute;
  transform: translate(-50%, 0);
  top: -110px;
  width: 250px;
  text-align: center;
  display: block;
  margin-top: 2em;
  left: 50%;
}