.authForm {
  width: 100%;
  :global(.ant-form-item-label) {
    line-height: 1.3 !important;
  }
  label {
    font-size: 1.1rem;
  }
  :global(.row .ant-form-item-children) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :global(.ant-alert) {
    width: 100%;
  }
}