.box {
  padding: 3rem 0;
}

.footer {
  font-size: 15px !important;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.listItem {
  margin: 0 8px;
}

.copyright {
  text-align: center;
}