.customStyle {
  line-height: 18px;
  margin-top: -1px;
}
.customStyle.react-tel-input .form-control {
  height: 32px;
  width: 100%;
  padding-right: 10px;
  border: 1px solid #d9d9d9;
}

.customStyle.react-tel-input .form-control.ant-input-lg {
  height: 40px;
  font-size: 16px;
}

.customStyle.react-tel-input .flag-dropdown {
  border: 1px solid #d9d9d9;
}

.customStyle.react-tel-input .form-control:focus {
  border-color: #40a9ff;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.customStyle.react-tel-input .form-control:hover {
  border-color: #40a9ff;
}
.customStyle.react-tel-input .form-control:hover + .flag-dropdown,
.customStyle.react-tel-input .form-control:focus + .flag-dropdown {
  border-color: #40a9ff;
  border-right-color: #d9d9d9;
}

.customStyle.react-tel-input input {
  border-radius: 2px;
}
.customStyle.react-tel-input input::placeholder {
  color: #bfbfbf;
}

.reactPhoneInput {
  padding-left: 60px !important;
}
