.background {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.45);
  z-index: 100000;
}

.img {
  width: 100%;
  max-width: 300px;
}

.result {
  width: 700px;
  max-width: 80%;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.03), 0 0 10px 0 rgba(20, 19, 34, 0.02);
}