@import 'components/mixins.scss';

.activeLng {
  position: relative;
  text-transform: uppercase;
  font-size: 1rem;
  margin: 0 1rem;
  padding: 0 1rem;
  cursor: pointer;
  &:after {
    opacity: 0.8;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -2px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  @media (max-width: $md-min-width) {
    font-size: 1.3rem;
  }
}

.language {
  display: flex;
  gap: 6px;
  min-width: 80px;
  padding: 2px 4px;
}

.flag {
  max-width: 20px;
  width: 20px;
}