@import 'src/components/mixins.scss';

.root {
  line-height: 1.3;
  color: $primary;
  &:hover {
    opacity: 0.8;
  }
}

.underlined {
  border-bottom: 1px solid rgba(57, 55, 73, 0.25);
}