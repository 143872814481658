@import 'components/mixins.scss';

.inner {
  box-shadow: 0 4px 10px 0 rgba(20, 19, 34, 0.03), 0 0 10px 0 rgba(20, 19, 34, 0.02);
  margin: 0 auto !important;
  padding: 3.84rem 3.07rem 1.53rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  position: relative;
  overflow: visible;
  min-width: 40rem;
  max-width: 70rem;

  &_oneColumn {
    min-width: 25rem;
    max-width: 45rem;
  }

  @media (max-width: $lg-min-width) {
    padding: 2.3rem 2.3rem 0.76rem;
    min-width: 25rem;
  }
}

.block {
  min-width: 40rem;
  max-width: 75rem;
  margin: 0 auto;
  margin-top: 0;
  padding: 3.84rem 3.07rem 1.53rem;

  &_oneColumn {
    min-width: 25rem;
    max-width: 45rem;
  }

  &_withMarginTop {
    margin-top: 7rem;
  }

  @media (max-width: $lg-min-width) {
    padding: 1.53rem;
    min-width: 25rem;
  }
}