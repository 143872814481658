@import 'components/mixins.scss';

.backButton {
  margin: 0 10px 10px 0 !important;
}

.environmentTag {
  margin-left: 8px !important;
  vertical-align: text-top;
}

.forgotPassword {
  margin-top: 16px;
  margin-bottom: 24px;
  height: 40px;
  padding-top: 10px;
  font-size: 14px;
}

.styledAlert {
  margin-bottom: 24px !important;
  margin-right: 0 !important;
}

.title {
  margin-bottom: 2rem;
}

.action {
  font-weight: 600;
  margin-top: 18px;
}

.signUpLink {
  text-align: center;
}

.twoFaButtons {
  display: flex;
  justify-content: space-between;
}